



@font-face {
  font-family: 'Mail Sans Roman';
  src: url('./assets/fonts/MailSansRoman-Light.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Light.woff') format('woff');
  font-weight: 300; 
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Regular.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src:local('Mail Sans Roman'), 
   url('./assets/fonts/MailSansRoman-Medium.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Medium.woff') format('woff');
  font-weight: 500; /* Normal weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Bold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 600; /* Normal weight */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-DemiBold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 700; /* Normal weight */
  font-style: normal;
  font-display: swap;
}

/* outfit-regular - latin */
@font-face {
  font-display: swap; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/outfit-v11-latin-regular.woff2') format('woff2'); 
}
/* outfit-500 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/outfit-v11-latin-500.woff2') format('woff2'); 
}
/* outfit-600 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/outfit-v11-latin-600.woff2') format('woff2'); 
}
/* outfit-700 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/outfit-v11-latin-700.woff2') format('woff2'); 
}
/* outfit-800 - latin */
@font-face {
  font-display: swap; 
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/outfit-v11-latin-800.woff2') format('woff2'); 
}

/* dm-sans-regular - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/dm-sans-v15-latin-regular.woff2') format('woff2');
}
/* dm-sans-500 - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/dm-sans-v15-latin-500.woff2') format('woff2');
}
/* dm-sans-600 - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/dm-sans-v15-latin-600.woff2') format('woff2');
}
/* dm-sans-600italic - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/dm-sans-v15-latin-600italic.woff2') format('woff2');
}
/* dm-sans-700 - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/dm-sans-v15-latin-700.woff2') format('woff2');
}
/* dm-sans-800 - latin */
@font-face {
  font-display: swap; 
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/dm-sans-v15-latin-800.woff2') format('woff2');
}


code {
  font-family:  'Mail Sans Roman', Arial, sans-serif;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:  'Mail Sans Roman', Arial, sans-serif;
}

body {
margin: 0;
font-family:  'Mail Sans Roman', Arial, sans-serif;


overflow-x: hidden !important;
background-color: white;
color:#1D2939;


}

@media (max-width:576px){
  body{background-color: #FBFDFF !important;}
}


